// *************************************
// *****  LIGHT BACKGROUND THEMES  *****
// *************************************


// white as basis for non-site pages

:root, .theme--white, .theme--daytona-beach, .theme--asteroid-belt {
    @include on-light-theme-vars;
    background: var(--theme--background);
    color: var(--theme--color);
}

// daytona-beach

.theme--daytona-beach {
    // background colours
    --theme--background-color: #{$brand--yellow};
    --theme--background: #{$brand--yellow};
    background: var(--theme--background);
    // colours
    color: var(--theme--color);
    --theme--heading--color: #{$brand--navy};
    --theme--accent--color: #{$brand--pink};
    --theme--accent--color--invert: #{$brand--yellow};
    // links
    --theme--link--color: #{$brand--navy};
    --theme--link--text-decoration--color: #{rgba($brand--navy, .5)};
    --theme--link--color--hover: #{$brand--navy};
    --theme--link--text-decoration--color--hover: #{$brand--navy};
    // themed-panel
    --theme--panel-border-color: #{$brand--white};
}

// asteroid-belt

.theme--asteroid-belt {
    // background colours
    --theme--background-color: #{$brand--green};
    --theme--background: #{$brand--green};
    background: var(--theme--background);
    // colours
    color: var(--theme--color);
    --theme--heading--color: #{$brand--navy};
    --theme--accent--color: #{$brand--pink};
    --theme--accent--color--invert: #{$brand--white};
    // links
    --theme--link--text-decoration--color: #{rgba($brand--navy, .5)};
    // themed-panel
    --theme--panel-border-color: #{$brand--white};
    // themed buttons
    --theme--btn--color: #{$brand--white};
    --theme--btn--border-color: #{$brand--pink};
    --theme--btn--bg: #{$brand--pink};
    --theme--btn--color--hover: #{$brand--navy};
    --theme--btn--border-color--hover: #{$brand--white};
    --theme--btn--bg--hover: #{$brand--white};
}


// ************************************
// *****  DARK BACKGROUND THEMES  *****
// ************************************



.theme--navy-gradient, body.site-layout, .module--demo-area, .theme--pink-gradient, .theme--blue-gradient, .theme--nevada-desert, .theme--red-dwarf, .theme--asteroid-belt {
    @include on-dark-theme-vars;
}

// navy-gradient

.theme--navy-gradient, body.site-layout, .module--demo-area {
    // background colours
    background: var(--theme--background);
    // colours
    color: var(--theme--color);
}

// pink-gradient

.theme--pink-gradient {
    // background colours
    --theme--background-color: #{$brand--pink};
    --theme--background: #{linear-gradient(50deg, $brand--pink 5%, rgb(130,30,250) 95%)};
    background: var(--theme--background);
    // colours
    color: var(--theme--color);
    // themed buttons
    --theme--btn--color: #{$brand--white};
    --theme--btn--border-color: #{$brand--blue};
    --theme--btn--bg: #{$brand--blue};
    --theme--btn--color--hover: #{$brand--navy};
    --theme--btn--border-color--hover: #{$brand--white};
    --theme--btn--bg--hover: #{$brand--white};
    // form elements
    --theme--form--checked--bg-color: #{$brand--blue};
    --theme--form--checked--border-color: #{$brand--blue};
}

// blue-gradient

.theme--blue-gradient {
    // background colours
    --theme--background-color: #{$brand--navy};
    --theme--background: #{linear-gradient(50deg, rgb(90,55,250) 25%, rgb(0,255,245) 100%)};
    background: var(--theme--background);
    // colours
    color: var(--theme--color);
}

// nevada-desert

.theme--nevada-desert {
    // background colours
    --theme--background-color: #{$brand--orange};
    --theme--background: #{$brand--orange};
    background: var(--theme--background);
    // colours
    color: var(--theme--color);
}

// red-dwarf

.theme--red-dwarf {
    // background colours
    --theme--background-color: #{$brand--purple};
    --theme--background: #{linear-gradient(80deg, $brand--purple 25%, $brand--navy 100%)};
    background: var(--theme--background);
    // colours
    color: var(--theme--color);
}
