.testimonial {
    padding: 0;
    margin: 0;
    text-align: center;
    &.top-line {
        border-top: 1px solid var(--theme--panel-border-color);
        padding-top: var(--panel-padding);
    }
    &--star-rating {
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 0.6rem;
        &--left-line, &--right-line {
            width: 48%;
            flex: 0 1 auto;
            border-top: 2px solid var(--theme--panel-border-color);
        }
        .stars {
            width: fit-content;
            flex: 0 1 auto;
            color: var(--theme--color);
            font-size: 1.2rem;
            white-space: nowrap;
            transform: translateY(-45%);
            svg {
                margin-right: 0.1em;
            }
        }
    }
    footer {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        gap: 0.6rem;
        font-style: normal;
        .footer--left-line, .footer--right-line {
            width: 48%;
            flex: 0 1 auto;
            border-bottom: 2px solid var(--theme--panel-border-color);
        }
        cite {
            width: fit-content;
            flex: 0 1 auto;
            transform: translateY(.25em);
            strong {
                font-weight: $headings-font-weight;
                white-space: nowrap;
            }
        }
    }
}
