@font-face {
    font-family: 'riftonitalic';
    src: url('../../fonts/rifton-italic-webfont.eot');
    src: url('../../fonts/rifton-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/rifton-italic-webfont.woff2') format('woff2'),
         url('../../fonts/rifton-italic-webfont.woff') format('woff'),
         url('../../fonts/rifton-italic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/* saira-semi-condensed-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Saira Semi Condensed';
    font-style: normal;
    font-weight: 300;
    src: url('../../fonts/saira-semi-condensed-v11-latin-300.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/saira-semi-condensed-v11-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/saira-semi-condensed-v11-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/saira-semi-condensed-v11-latin-300.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/saira-semi-condensed-v11-latin-300.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* saira-semi-condensed-500 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Saira Semi Condensed';
    font-style: normal;
    font-weight: 500;
    src: url('../../fonts/saira-semi-condensed-v11-latin-500.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/saira-semi-condensed-v11-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/saira-semi-condensed-v11-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/saira-semi-condensed-v11-latin-500.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/saira-semi-condensed-v11-latin-500.ttf') format('truetype'); /* Safari, Android, iOS */
}
/* saira-semi-condensed-900 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Saira Semi Condensed';
    font-style: normal;
    font-weight: 900;
    src: url('../../fonts/saira-semi-condensed-v11-latin-900.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/saira-semi-condensed-v11-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/saira-semi-condensed-v11-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/saira-semi-condensed-v11-latin-900.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/saira-semi-condensed-v11-latin-900.ttf') format('truetype'); /* Safari, Android, iOS */
}
