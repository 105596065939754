.btn-unstyled {
    appearance: none;
    border: 0;
    margin: 0;
    padding: 0;
    font-size: 1em;
    font-weight: inherit;
    color: inherit;
    cursor: pointer;
    background-color: transparent;
}

a, .btn, button {
    svg {
        font-size: $font-size-base;
    }
    svg.fa-arrow-right, svg.fa-angle-right, svg.fa-arrow-left, svg.fa-angle-left, svg.fa-arrow-down, svg.fa-angle-down, svg.fa-caret-down, &[data-bs-toggle="collapse"] svg {
        will-change: transform;
        transition: transform 300ms ease-in-out;
    }
    svg.fa-arrow-right, svg.fa-angle-right {
        margin-left: 0.3em;
    }
    svg.fa-arrow-left, svg.fa-angle-left {
        margin-right: 0.3em;
    }
    svg.fa-arrow-down, svg.fa-angle-down, svg.fa-caret-down, &[data-bs-toggle="collapse"] svg {
        margin-left: 0.3em;
    }
    &:not(:disabled):not(.disabled) {
        &:hover, &:focus {
            svg.fa-arrow-right, svg.fa-angle-right {
                transform: translate3d(0.3em,0,0);
            }
            svg.fa-arrow-left, svg.fa-angle-left {
                transform: translate3d(-0.3em,0,0);
            }
        }
        &[aria-expanded="true"] {
            svg.fa-arrow-down, svg.fa-angle-down, svg.fa-caret-down, &[data-bs-toggle="collapse"] svg {
                transform: rotate(180deg);
            }
        }
    }
    &:disabled, &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
}

.btn {
    text-transform: uppercase;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    font-style: $headings-font-style;
    letter-spacing: $headings-letter-spacing;
    @include non-retina-font-smoothing-antialiased;
}

.btn.btn-theme {
    position: relative;
    z-index: 2;
    color: var(--theme--btn--color);
    border-color: transparent !important;
    background: transparent !important;
    transition: all 500ms linear;
    font-size: $btn-font-size;
    line-height: calc(#{$btn-font-size} * 1.2);
    text-decoration: none;
    padding: .79rem 1.25rem .65rem;
    &.btn-sm {
        font-size: $btn-font-size-sm;
    }
    &.btn-lg {
        font-size: $btn-font-size-lg;
    }
    &:before {
        display: block;
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        border: $btn-border-width;
        background: var(--theme--btn--bg);
        border-color: var(--theme--btn--border-color);
        transition: all 500ms linear;
        transform: skewX($theme-skew-angle);
    }
    &:hover, &:focus {
        color: var(--theme--btn--color--hover);
        border-color: transparent !important;
        background: transparent !important;
        text-decoration: none;
        transition-duration: 100ms;
        &:before {
            background-color: var(--theme--btn--bg--hover);
            border-color: var(--theme--btn--border-color--hover);
            transition-duration: 100ms;
        }
    }
}
