:root {

    --vpHeight: 100vh;
    --vpWidth: 100vw;
    --vpInitialHeight: 100vh;
    
    // ******************************
    // *****  layout variables  *****
    // ******************************

    --edge-padding: 30px;

    @include media-breakpoint-up(sm) {
        // bootstrap column spacing
        .row {
            --bs-gutter-x: 2rem;
            .col {
                --bs-gutter-x: #{$grid-gutter-width};
            }
        }
    }

}

// body

body.site-layout {
    display: flex;
    flex-direction: column;
    min-height: var(--vpHeight);
    scroll-behavior: smooth;

    #main-content {
        flex: 1 1 auto;
    }

    #main-footer {
        flex: 0 0 auto;
    }

    .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
        padding-left: var(--edge-padding);
        padding-right: var(--edge-padding);
    }
}

// Link colours

a:not(.btn, .round-icon-btn, .dropdown-item, .page-link, .visual-listing--item), .btn.btn-link {
    color: var(--theme--link--color);
    text-decoration: var(--theme--link--text-decoration--color);
    &:hover, &:focus {
        color: var(--theme--link--color--hover);
        text-decoration: var(--theme--link--text-decoration--hover);
    }
    main p > &:not([class="*"]) {
        text-decoration: underline;
    }
}

// rules

hr {
    border-color: var(--theme--rule--color);
    opacity: 1;
}

// fullscreen-top-section

.fullscreen-top-section {
    display: flex;
    flex-direction: column;
    --top-section-min-height: max(#{map-get($hero--img--min-heights, xs)}, var(--vpHeight));
    min-height: var(--top-section-min-height);
    @include media-breakpoint-up(sm) {
        --top-section-min-height: max(#{map-get($hero--img--min-heights, sm)}, var(--vpHeight));
        min-height: var(--top-section-min-height);
    }
    @include media-breakpoint-up(md) {
        --top-section-min-height: max(#{map-get($hero--img--min-heights, md)}, var(--vpHeight));
        min-height: var(--top-section-min-height);
    }
    @include media-breakpoint-up(lg) {
        --top-section-min-height: max(#{map-get($hero--img--min-heights, lg)}, var(--vpHeight));
        min-height: var(--top-section-min-height);
    }
    @include media-breakpoint-up(xl) {
        --top-section-min-height: max(#{map-get($hero--img--min-heights, xl)}, var(--vpHeight));
        min-height: var(--top-section-min-height);
    }
    @include media-breakpoint-up(xxl) {
        --top-section-min-height: max(#{map-get($hero--img--min-heights, xxl)}, var(--vpHeight));
        min-height: var(--top-section-min-height);
    }
}

// sections

.page-section {
    position: relative;
    z-index: 2;
    background: transparent !important;
    &--background {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        background: var(--theme--background);
    }
    &--content {
        position: relative;
        z-index: 1;
    }
    &.min-full-height {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height:  var(--vpHeight);
    }
    &.theme--navy-gradient, &.theme--pink-gradient, &.theme--blue-gradient, &.theme--white, &.theme--daytona-beach, &.theme--nevada-desert, &.theme--red-dwarf, &.theme--asteroid-belt {
        .page-section--background {
            background: transparent !important;
            overflow: visible;
            clip-path: inset(0 0 0 0);
            &:before {
                display: block;
                content: '';
                position: fixed;
                z-index: -1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: var(--theme--background);
            }
        }
        &.theme--bg-grid-fx .page-section--background:before {
            background: url('../../img/backgrounds/chaos-grid--top-right.svg'), url('../../img/backgrounds/chaos-grid--bottom-left.svg'), var(--theme--background);
            background-position: 100% 0, 0 100%, 0 0;
            background-size: max(50vw, 500px) auto, max(50vw, 500px) auto, 100% 100%;
            background-repeat: no-repeat;
        }
    }
}

// page title area

@keyframes breadcrumb-reveal {
    from {
        transform: translateY(105%);
    }
    to {
        transform: translateY(0);
    }
}

.page-title-area {
    margin-top: 40vw;
    @include media-breakpoint-up(sm) {
        margin-top: 35vw;
    }
    @include media-breakpoint-up(md) {
        margin-top: 240px;
    }
    .hero + & {
        margin-top: 40px !important;
    }
    .breadcrumb {
        font-size: 1rem;
        --bs-breadcrumb-divider-color: #{rgba(white, .4)};
        margin: 0;
        justify-content: center;
        li {
            a {
                text-decoration: none;
                &:hover, &:focus {
                    text-decoration: underline;
                }
            }
        }
    }
}

// sticky-side-wrapper

.sticky-side-wrapper {
    position: relative;
    .sticky-side-content {
        display: block;
        position: sticky;
        top: var(--panel--padding);
    }
}

// explosion-left-layout

.explosion-left-layout {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    .explosion-left-layout--content {
        display: block;
        width: 100%;
        padding-left: var(--edge-padding);
        padding-right: var(--edge-padding);
    }
    .explosion-left-layout--artwork {
        display: block;
        width: 100%;
        img {
            display: block;
            width: 100%;
            height: calc(var(--vpWidth) * 0.636);
            max-height: 300px;
            object-position: 50% 100%;
            object-fit: contain;
            transform-origin: 50% 100%;
            transform: scale(0);
            &.lazyloaded.hunt-in {
                transform: scale(1);
                transition: transform 500ms cubic-bezier(0.34, 1.56, 0.64, 1);
            }
        }
    }
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        gap: 0;
        .explosion-left-layout--content {
            display: flex;
            align-items: center;
            order: 2;
            width: calc(#{map-get($container-max-widths, lg)} / 2);
            flex: 0 0 auto;
        }
        .explosion-left-layout--artwork {
            display: flex;
            align-items: flex-end;
            order: 1;
            width: 50%;
            flex: 0 0 auto;
            padding-right: var(--edge-padding);
            padding-top: var(--edge-padding);
            img {
                display: block;
                width: 100%;
                height: 100%;
                max-height: none;
                object-position: 50% 100%;
                object-fit: contain;
            }
        }
    }
    @include media-breakpoint-up(xl) {
        .explosion-left-layout--content {
            width: calc(#{map-get($container-max-widths, xl)} / 2);
        }
    }
    @include media-breakpoint-up(xxl) {
        .explosion-left-layout--content {
            width: calc(#{map-get($container-max-widths, xxl)} / 2);
        }
    }
}

// small-explosion layouts

.small-explosion-layout {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    .small-explosion-layout--content {
        display: block;
        width: 100%;
        padding-left: var(--edge-padding);
        padding-right: var(--edge-padding);
    }
    .small-explosion-layout--artwork {
        display: block;
        width: 100%;
        &--inner {
            width: 100%;
        }
        img {
            display: block;
            width: 100%;
            height: var(--vpWidth);
            max-height: 300px;
            object-position: 0 100%;
            object-fit: contain;
            transform-origin: 0 100%;
            transform: scale(0);
            &.lazyloaded.hunt-in {
                transform: scale(1);
                transition: transform 500ms cubic-bezier(0.34, 1.56, 0.64, 1);
            }
        }
    }
    @include media-breakpoint-up(lg) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        width: calc(100% - ((100% - #{map-get($container-max-widths, lg)}) / 2));
        gap: 0;
        .small-explosion-layout--content {
            display: flex;
            align-items: center;
            width: 60%;
            flex: 1 1 auto;
            order: 2;
        }
        .small-explosion-layout--artwork {
            display: flex;
            align-items: flex-end;
            width: min(40%, 500px);
            flex: 1 1 auto;
            padding-right: var(--edge-padding);
            padding-top: var(--edge-padding);
            order: 1;
            img {
                display: block;
                width: 100%;
                height: 100%;
                max-height: none;
                max-width: 500px;
                object-position: 50% 100%;
                object-fit: contain;
            }
        }
    }
    @include media-breakpoint-up(xl) {
        width: calc(100% - ((100% - #{map-get($container-max-widths, xl)}) / 2));
    }
    @include media-breakpoint-up(xxl) {
        width: calc(100% - ((100% - #{map-get($container-max-widths, xxl)}) / 2));
    }
    &.explosion-right {
        .small-explosion-layout--artwork {
            &--inner {
                transform: scaleX(-1);
            }
        }
        @include media-breakpoint-up(lg) {
            margin-left: auto;
            justify-content: flex-end;
            .small-explosion-layout--content {
                order: 1;
            }
            .small-explosion-layout--artwork {
                order: 2;
                padding-left: var(--edge-padding);
                padding-right: 0;
            }
        }
    }
}

// scale reveal animation

.hunt-scale-up.hunt-watch {
    transform: scale(0);
    transition: transform 1s ease-in;
    &.hunt-in {
        transform: scale(1);
        transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
    }
}

// pick-up-items--decor

@keyframes pick-up-item-bobble {
    0% {
        transform: translate3d(0,0,0) rotate(0) scale(1);
    }
    12% {
        transform: translate3d(1%,-2%,0) rotate(2deg) scale(0.95);
    }
    25% {
        transform: translate3d(2%,0,0) rotate(4deg) scale(0.9);
    }
    37% {
        transform: translate3d(1%,2%,0) rotate(2deg) scale(0.95);
    }
    50% {
        transform: translate3d(0,0,0) rotate(0) scale(1);
    }
    63% {
        transform: translate3d(-1%,-2%,0) rotate(-2deg) scale(0.95);
    }
    75% {
        transform: translate3d(-2%,0,0) rotate(-4deg) scale(0.9);
    }
    87% {
        transform: translate3d(-1%,2%,0) rotate(-2deg) scale(0.95);
    }
    100% {
        transform: translate3d(0,0,0) rotate(0) scale(1);
    }
}

.pick-up-items--decor {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 0;
    pointer-events: none;
    z-index: 3;
    --img-max-width: 100px;
    &--inner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 20px;
        transform: translateY(-50%);
        mask-image: linear-gradient(0deg, black 25%, rgba(black, 0.85) 25%, rgba(black, 0.85) 50%, black 50%, black 75%, rgba(black, 0.85) 75%, rgba(black, 0.85) 100%);
        mask-size: 8px 8px;
        .pick-up-items--decor--item {
            flex: 1 1 auto;
            width: calc((100% - 60px) / 4);
            max-width: var(--img-max-width);
            transform-origin: 50% 50%;
            filter: brightness(1.3);
            img {
                display: block;
                max-width: var(--img-max-width);
                transform: scale(0);
                will-change: transform;
                transition: transform 3s ease-in-out;
            }
        }
        &.hunt-in {
            .pick-up-items--decor--item {
                animation: pick-up-item-bobble 1.5s infinite linear;
                img {
                    display: block;
                    transform: scale(0);
                    will-change: transform;
                    transition: transform 500ms ease-in;
                    &.lazyloaded {
                        transform: scale(1);
                        transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
                        transition-delay: 300ms;
                    }
                }
                &:nth-child(2) {
                    animation-duration: 1.9s;
                    img.lazyloaded {
                        transition-delay: 400ms;
                    }
                }
                &:nth-child(3) {
                    animation-duration: 1.4s;
                    img.lazyloaded {
                        transition-delay: 500ms;
                    }
                }
                &:nth-child(4) {
                    animation-duration: 1.7s;
                    img.lazyloaded {
                        transition-delay: 600ms;
                    }
                }
            }
        }
    }
    @include media-breakpoint-up(sm) {
        max-width: #{map-get($container-max-widths, sm)};
    }
    @include media-breakpoint-up(md) {
        max-width: #{map-get($container-max-widths, md)};
        --img-max-width: 120px;
    }
    @include media-breakpoint-up(lg) {
        max-width: #{map-get($container-max-widths, lg)};
        --img-max-width: 140px;
    }
    @include media-breakpoint-up(xl) {
        max-width: #{map-get($container-max-widths, xl)};
    }
    @include media-breakpoint-up(xxl) {
        max-width: #{map-get($container-max-widths, xxl)};
    }
}

// split-img-content-layout

.split-img-content-layout {
    display: flex;
    flex-direction: column;
    .split-img-content-layout--visual {
        width: 100%;
        position: relative;
    }
    .split-img-content-layout--content {
        width: 100%;
        padding-left: var(--edge-padding);
        padding-right: var(--edge-padding);
    }
    @include media-breakpoint-up(xl) {
        flex-direction: row;
        align-items: stretch;
        .split-img-content-layout--visual {
            width: calc(((100% - #{map-get($container-max-widths, xl)}) / 2) + (#{map-get($container-max-widths, xl) * 0.6}));
            flex: 0 0 auto;
            min-height: 100%;
            &--main-image {
                display: block;
                width: 100%;
                height: 100%;
                clip-path: polygon(0 0, calc(100% - 50px) 0, 100% 100%, 0 100%);
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .split-img-content-layout--content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: calc(#{map-get($container-max-widths, xl)} * 0.4);
            flex: 0 0 auto;
            min-height: 100%;
            .split-img-content-layout--content--inner {
                width: 100%;
            }
        }
        &.split-img-content-layout--visual-right {
            flex-direction: row-reverse;
            .split-img-content-layout--visual {
                &--main-image {
                    clip-path: polygon(50px 0, 100% 0, 100% 100%, 0 100%);
                }
            }
        }
    }
    @include media-breakpoint-up(xxl) {
        .split-img-content-layout--visual {
            width: calc(((100% - #{map-get($container-max-widths, xxl)}) / 2) + (#{map-get($container-max-widths, xxl) * 0.6}));
        }
        .split-img-content-layout--content {
            width: calc(#{map-get($container-max-widths, xxl)} * 0.4);
        }
    }
}

// social links

.social-links {
    &--logo {
        display: block;
        margin: 0 auto;
        width: 100%;
        max-width: 300px;
        transform: translateY(-25%);
    }
    &--list {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
        margin: 0;
        li {
            width: fit-content;
            margin: 0 !important;
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                border: 2px solid $brand--white;
                color: $brand--white;
                background-color: rgba(black, .4);
                backdrop-filter: blur(5px);
                transition: background-color 250ms linear;
                text-decoration: none;
                transform: skewX($theme-skew-angle);
                svg {
                    font-size: 20px;
                    transform: skewX($theme-skew-angle-correction);
                }
                &:hover, &:focus {
                    background-color: $brand--pink;
                    color: $brand--white;
                    text-decoration: none;
                }
                @include media-breakpoint-up(md) {
                    width: 104px;
                    height: 60px;
                    svg {
                        font-size: 32px;
                    }
                }
            }
        }
    }
}
