::placeholder {
    color: $brand--blue !important;
}

.hide-border {
    border-color: transparent;
    background-clip: border-box;
    &.form-check .form-check-input {
        border-color: transparent;
        background-clip: border-box;
    }
}

.form-control-slant-wrapper {
    display: inline-block;
    position: relative;
    z-index: 2;
    width: 100%;
    height: fit-content;
    &:before {
        display: block;
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: $input-border-width solid white;
        background-color: white;
        transform: skewX($theme-skew-angle);
    }
    .form-control {
        background-color: transparent !important;
        border-color: transparent !important;
    }
    &:has(.form-control:focus) {
        &:before {
            border-color: #86b7fe;
            box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
        }
        .form-control {
            box-shadow: none !important;
        }
    }
}

.form-check {
    clear: both;
    display: flex;
    gap: 0.5rem;
    .form-check-input[type="checkbox"] {
        width: 1.4rem;
        height: 1.4rem;
        flex: 0 0 auto;
        transform: skewX($theme-skew-angle);
        &:checked {
            background-color: var(--theme--form--checked--bg-color);
            border-color: var(--theme--form--checked--border-color);
            background-image: var(--theme--form--checkbox--indicator);
            background-size: 75% 98%;
        }
        &[type="checkbox"] {
            border-radius: 0;
        }
    }
    .form-check-label {
        width: 100%;
        flex: 0 1 auto;
        color: var(--theme--color);
        padding-top: 0.2rem;
    }
}
.form-check .form-check-input[type="radio"] {
    width: 1.4rem;
    height: 1.4rem;
    &:checked {
        background-color: var(--theme--form--checked--bg-color);
        border-color: var(--theme--form--checked--border-color);
        background-image: var(--theme--form--radio--indicator);
    }
}
