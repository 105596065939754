@mixin non-retina-font-smoothing-antialiased {
    @media(-webkit-min-device-pixel-ratio: 1) {
        -webkit-font-smoothing: antialiased;
    }
}

// theme mixins

@mixin on-light-theme-vars {
    // background colours
    --theme--background-color: #{$brand--white};
    --theme--background: var(--theme--background-color);
    background: var(--theme--background);
    // colours
    --theme--color: #{$brand--navy};
    color: var(--theme--color);
    --theme--heading--color: #{$brand--navy};
    --theme--accent--color: #{$brand--pink};
    --theme--accent--color--invert: white;
    // links
    --theme--link--color: #{$brand--pink};
    --theme--link--text-decoration--color: #{rgba($brand--pink, .5)};
    --theme--link--color--hover: #{$brand--navy};
    --theme--link--text-decoration--color--hover: #{$brand--navy};
    // icons, bullets, rules etc
    --theme--decor--color: #{$brand--navy};
    --theme--rule--color: #{rgba($brand--navy, 0.2)};
    // themed-panel
    --theme--panel-border-color: #{$brand--yellow};
    // themed buttons
    --theme--btn--color: #{$brand--white};
    --theme--btn--border-color: #{$brand--pink};
    --theme--btn--bg: #{$brand--pink};
    --theme--btn--color--hover: #{$brand--white};
    --theme--btn--border-color--hover: #{$brand--navy};
    --theme--btn--bg--hover: #{$brand--navy};
    // form elements
    --theme--form--checked--bg-color: #{$brand--pink};
    --theme--form--checked--border-color: #{$brand--pink};
    --theme--form--checkbox--indicator: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' preserveAspectRatio='none'%3E%3Cpath fill='%23ffffff' d='M447.9 142.5l-23.2 22L181 395.3l-22 20.8-22-20.8L23.2 287.6 0 265.6l44-46.5 23.2 22L159 328 380.7 118l23.2-22 44 46.5z'/%3E%3C/svg%3E");
    --theme--form--radio--indicator: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}

@mixin on-dark-theme-vars {
    // background colours
    --theme--background-color: #{$brand--navy};
    --theme--background: #{linear-gradient(50deg, rgb(132,28,222) 5%, $brand--navy 30%, $brand--navy 70%, rgb(0,125,240) 95%)};
    background: var(--theme--background);
    // colours
    --theme--color: #{$brand--white};
    color: var(--theme--color);
    --theme--heading--color: #{$brand--white};
    --theme--accent--color: #{$brand--turquoise};
    --theme--accent--color--invert: #{$brand--navy};
    // links
    --theme--link--color: #{$brand--white};
    --theme--link--text-decoration--color: #{rgba($brand--white, .5)};
    --theme--link--color--hover: #{$brand--white};
    --theme--link--text-decoration--color--hover: #{$brand--white};
    // icons, bullets, rules etc
    --theme--decor--color: #{$brand--white};
    --theme--rule--color: #{rgba($brand--white, 0.4)};
    // themed-panel
    --theme--panel-border-color: #{$brand--white};
    // themed buttons
    --theme--btn--color: #{$brand--white};
    --theme--btn--border-color: #{$brand--pink};
    --theme--btn--bg: #{$brand--pink};
    --theme--btn--color--hover: #{$brand--navy};
    --theme--btn--border-color--hover: #{$brand--white};
    --theme--btn--bg--hover: #{$brand--white};
    // form elements
    --theme--form--checked--bg-color: #{$brand--pink};
    --theme--form--checked--border-color: #{$brand--pink};
    --theme--form--checkbox--indicator: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' preserveAspectRatio='none'%3E%3Cpath fill='%23ffffff' d='M447.9 142.5l-23.2 22L181 395.3l-22 20.8-22-20.8L23.2 287.6 0 265.6l44-46.5 23.2 22L159 328 380.7 118l23.2-22 44 46.5z'/%3E%3C/svg%3E");
    --theme--form--radio--indicator: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
