.expander {
    .expander--header {
        button {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
            border: 0;
            padding: 0;
            background-color: transparent;
            font-family: inherit;
            font-weight: inherit;
            font-size: inherit;
            text-transform: inherit;
            line-height: inherit;
            color: var(--theme--link--color);
            .indicator {
                svg {
                    width: 27px;
                    height: 34px;
                    margin: -8px 0;
                }
            }
            &:hover, &:focus {
                color: var(--theme--link--color--hover);
                text-decoration: var(--theme--link--text-decoration--hover);
            }
        }
    }
    .expander--content {
        & > *:last-child {
            margin-bottom: 0;
        }
    }
}
