#main-menu {
    display: none;
    opacity: 0;
    pointer-events: hidden;
    position: fixed;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background: #{linear-gradient(50deg, rgb(132,28,222) 5%, $brand--navy 30%, $brand--navy 70%, rgb(0,125,240) 95%)};
    will-change: opacity;
    &:before {
        position: fixed;
    }

    &[aria-hidden="false"] {
        display: block;
        opacity: 0;
        transition: opacity 200ms linear;
    }
    .menu-active & {
        opacity: 1;
        pointer-events: all;
    }

    ul {
        display: block;
        flex: 0 1 auto;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        @include media-breakpoint-up(md) {
            max-width: 600px;
        }

        li {
            display: block;
            width: 100%;
            margin: 0;
            padding: 0;
            overflow: hidden;
        }
    }
    /* submenu level */
    div.submenu {
        overflow: hidden;
        max-height: 0;
        transition: max-height 300ms ease-in-out;
    }
    /* expander level */
    div.submenu-expander {
        overflow: hidden;
        max-height: 0;
        transition: max-height 300ms ease-in-out;
    }
    /* search */
    #main-menu--search {
        display: flex;
        flex-direction: row;
        flex: 0 0 auto;
        justify-content: space-between;
        width: 100%;
        max-width: 400px;
        margin-top: 60px;
        padding: 0;
        .rough-input-wrapper {
            flex: 1 1 auto;
            margin: 0;
            input {
                padding-left: .5rem;
                width: 100%;
            }
        }
        button[type="submit"] {
            flex: 0 0 auto;
            margin-left: 10px;
        }
    }
}

/* menu items styling */

#main-menu {
    .main-menu--inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100%;
        padding: 200px 30px 60px;
        @include media-breakpoint-up(md) {
            padding-left: 60px;
            padding-right: 60px;
        }
        & > ul {

            a, button {
                appearance: none;
                display: block;
                width: 100%;
                margin: 0;
                padding: 15px 0;
                border: 0;
                text-align: left;
                font-size: 1.6rem;
                line-height: 1.2;
                background-color: transparent;
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                text-transform: uppercase;
                color: var(--theme--link--color);
                text-decoration: none;

                &:hover {
                    color: var(--theme--color);
                    text-decoration: underline;
                }

                &:focus {
                    color: white;
                    text-decoration: none;
                    outline: none;
                }
            }

            button {
                position: relative;

                .indicator {
                    display: inline-block;
                    position: absolute;
                    top: 15px;
                    right: 0;
                    will-change: transform;
                    transition: transform 300ms ease-in-out;
                    svg {
                        width: 27px;
                        height: 34px;
                        margin: -8px 0;
                    }
                }

                &[aria-expanded="true"] {
                    color: white;
                    .indicator {
                        transform: rotate(180deg);
                    }
                }
            }

            .submenu {
                .submenu--items {
                    --length: 0;
                    & > li {
                        --index: 0;
                        & > a, & > button {
                            font-family: $font-family-base;
                            font-weight: $font-weight-base;
                            text-transform: none;
                            font-size: 1.4rem;
                            line-height: 1.2;
                            padding-left: 20px;
                            transform: translate3d(-20px, 0, 0);
                            opacity: 0;
                            will-change: transform, opacity;
                            transition: transform 300ms ease-in-out, opacity 300ms linear;
                            transition-delay: 0;
                        }
                        & > button {
                            position: relative;
                            &:before {
                                display: inline-block;
                                position: absolute;
                                top: 15px;
                                right: 0;
                                content: '+';
                                font-family: $font-family-base;
                                font-weight: $font-weight-base;
                                text-transform: none;
                                font-size: 2rem;
                                line-height: 1.2;
                            }
                            &[aria-expanded="true"] {
                                &:before {
                                    content: '-';
                                }
                            }
                        }
                    }
                }
                &[aria-hidden="false"] .submenu--items li {
                    & > a, & > button {
                        opacity: 1;
                        transform: translate3d(0, 0, 0);
                        transition-delay: calc(100ms * var(--index));
                        transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
                    }
                }

                .submenu-expander {
                    .submenu-expander--items {
                        --length: 0;
                        padding: 5px 0 20px;

                        & > li {
                            --index: 0;

                            a, button {
                                font-family: $font-family-sans-serif;
                                font-weight: normal;
                                font-size: 1.3rem;
                                line-height: 1.4rem;
                                text-transform: none;
                                padding: 10px 0 10px 40px;
                                transform: translate3d(-40px, 0, 0);
                                opacity: 0;
                                will-change: transform, opacity;
                                transition: transform 300ms ease-in-out, opacity 300ms linear;
                                transition-delay: calc(50ms * ((var(--length) - 1) - var(--index))), calc(50ms * ((var(--length) - 1) - var(--index)));
                            }
                        }
                    }
                    &[aria-hidden="false"] .submenu-expander--items > li {
                        a, button {
                            transform: translate3d(0, 0, 0);
                            opacity: 1;
                            transition-delay: calc(100ms * var(--index)), calc(100ms * var(--index));
                        }
                    }
                }
            }
        }
    }
}
