h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: var(--theme--heading--color);
    margin-bottom: max(1rem, 0.4em);
    letter-spacing: $headings-letter-spacing;
    &.heading-text-shadow {
        filter: drop-shadow(0.1em 0.08em 0px var(--theme--heading-shadow--color));
        .themed-panel & {
            filter: drop-shadow(0.1em 0.08em 0px var(--theme--panel-heading-shadow--color));
        }
    }
    strong, b {
        font-weight: $headings-font-weight;
    }
}

h1, .h1, h2, .h2 {
    filter: drop-shadow(0.1em 0.08em 0px var(--theme--heading-shadow--color));
    .themed-panel & {
        filter: drop-shadow(0.1em 0.08em 0px var(--theme--panel-heading-shadow--color));
    }
}

a, .btn-link {
    color: var(--theme--color);
    text-decoration-color: var(--theme--link--decoration-color);
    &:hover, &:focus {
        color: var(--theme--color);
        text-decoration-color: var(--theme--color);
    }
}

.color-body {
    color: var(--theme--color) !important;
}

.text-muted {
    color: var(--theme--color) !important;
    opacity: .8;
}

.long-form-content { // for news stories etc
    font-weight: 300;
    line-height: 1.5;
    b, strong {
        font-weight: 900;
        @include non-retina-font-smoothing-antialiased;
    }
    .lead {
        font-weight: 500;
    }
}

// icon styling shims
.tripadvisor-icon, .tiktok-icon {
    display: inline-block;
    width: 1em;
    height: 1em;
}
