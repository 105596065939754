﻿// ********************
// **  Site colours  **
// ********************

$brand--black:              #000;
$brand--white:              #fff;

$brand--pink:               #f50096;
$brand--turquoise:          #0ad2c3;
$brand--blue:               #0a7df0;
$brand--navy:               #050a3c;
$brand--yellow:             #ffbe00;
$brand--orange:             #ff6e00;
$brand--purple:             #6e00ff;
$brand--green:              #8ce61e;


// ******************************************************************
// **                                                              **
// **   BOOTSTRAP VARIABLES - (overriding bootstrap defaults)      **
// **                                                              **
// ******************************************************************


// Fonts

$font-family-sans-serif:        'Saira Semi Condensed', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
$font-family-base:              $font-family-sans-serif;
$font-size-base:                1.375rem; // Assumes the browser default, typically `16px`
$font-weight-base:              500;
$line-height-base:              1.3;

$headings-font-family:          'riftonitalic', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
$headings-font-weight:          400;
$headings-line-height:          1.2;
$headings-font-style:           normal;
$headings-letter-spacing:  0.02em;

$h1-font-size:                $font-size-base * 2;
$h2-font-size:                $font-size-base * 1.6;
$h3-font-size:                $font-size-base * 1.4;
$h4-font-size:                $font-size-base * 1.2;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base * 0.8;

$lead-font-size:              $font-size-base * 1.1;
$lead-font-weight:            $font-weight-base;

// Buttons

$btn-padding-y:         .375rem;
$btn-padding-x:         .75rem;
$btn-font-family:       $headings-font-family;
$btn-font-size:         1rem;
$btn-line-height:       1;

$btn-padding-y-sm:      .25rem;
$btn-padding-x-sm:      .5rem;
$btn-font-size-sm:      0.8rem;

$btn-padding-y-lg:      .5rem;
$btn-padding-x-lg:      1rem;
$btn-font-size-lg:      1.2rem;

$btn-border-width:      1px;

$border-radius:                 0;
$border-radius-sm:              $border-radius;
$border-radius-lg:              $border-radius;

$btn-border-radius:                 0;
$btn-border-radius-sm:              $btn-border-radius;
$btn-border-radius-lg:              $btn-border-radius;

// Spacing
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
    (
        0: 0,
        1: (
            $spacer * 0.25,
        ),
        2: (
            $spacer * 0.5,
        ),
        3: $spacer,
        4: (
            $spacer * 1.5,
        ),
        5: (
            $spacer * 3,
        ),
        // added
        6: (
            $spacer * 4,
        ),
        // added
        7:
            (
                $spacer * 5,
            ),
        // added
        8:
            (
                $spacer * 6,
            )
            // added,
    ),
    $spacers
);


// ************************
// **                    **
// **   SITE VARIABLES   **
// **                    **
// ************************

$mask--solid:    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNk+A8AAQUBAScY42YAAAAASUVORK5CYII=);

$theme-skew-angle: -8deg;
$theme-skew-angle-correction: 8deg;

// **************
// **  Panels  **
// **************

$panel--padding: (
    xs: 25px,
    sm: 25px,
    md: 30px,
    lg: 30px,
    xl: 30px,
    xxl: 30px
);

// **************
// **  Header  **
// **************

$header--menu-btn-bar--width:                       20px;
$header--menu-btn-bar--height:                      4px;
$header--menu-btn-bar--spacing:                     3px;
$header--menu-btn-bar--border-radius:               0;


// *****************
// **  Hero area  **
// *****************

$hero--fullscreen--height-reduction--mobile:             30px;
$hero--fullscreen--height-reduction--desktop:            30px;
$hero--img--min-heights: (
    xs: var(--vpWidth),
    sm: calc(var(--vpWidth) * .75),
    md: calc(var(--vpWidth) * .5625),
    lg: calc(var(--vpWidth) * .5),
    xl: 500px,
    xxl: 500px
);
$hero--img--heights: (
    xs: var(--vpWidth),
    sm: calc(var(--vpWidth) * .75),
    md: calc(var(--vpWidth) * .5625),
    lg: calc(var(--vpWidth) * .5),
    xl: 500px,
    xxl: 500px
);
$hero--img--max-heights: (
    xs: var(--vpWidth),
    sm: calc(var(--vpWidth) * .75),
    md: calc(var(--vpWidth) * .5625),
    lg: calc(var(--vpWidth) * .5),
    xl: 500px,
    xxl: 500px
);

// ***********************
// **  Image galleries  **
// ***********************

$image-gallery--small-thumb-height: 120px;
$image-gallery--medium-thumb-height: 180px;
$image-gallery--large-thumb-height: 240px;
$image-gallery--thumb--margin: 5px;
$image-gallery--thumb--bg-color: $brand--black;

