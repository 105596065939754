// weapons listing 

.weapons-listing {
    --img-width: 100px;
    &--item {
        position: relative;
        padding-left: calc(var(--img-width) / 2);
        &--image {
            position: absolute;
            z-index: 3;
            top: 0;
            left: 0;
            width: var(--img-width);
            bottom: 0;
            pointer-events: none;
            mask-image: linear-gradient(0deg, black 25%, rgba(black, 0.85) 25%, rgba(black, 0.85) 50%, black 50%, black 75%, rgba(black, 0.85) 75%, rgba(black, 0.85) 100%);
            mask-size: 8px 8px;
            .weapons-listing--item--image--inner {
                width: 100%;
                img {
                    width: 100%;
                    height: calc(100% - 16px);
                    object-position: center center;
                    object-fit: contain;
                    transform: scale(0);
                    will-change: transform;
                    transition: transform 3s ease-in-out;
                    filter: brightness(1.3);
                }
            }
            &.hunt-in {
                .weapons-listing--item--image--inner {
                    animation: pick-up-item-bobble 1.5s infinite linear;
                    img {
                        display: block;
                        max-width: 160px;
                        transform: scale(0);
                        will-change: transform;
                        transition: transform 500ms ease-in;
                        &.lazyloaded {
                            transform: scale(1);
                            transition-timing-function: cubic-bezier(0.34, 1.56, 0.64, 1);
                            transition-delay: 300ms;
                        }
                    }
                }
            }
        }
        &--panel {
            position: relative;
            z-index: 1;
            padding-left: calc((var(--img-width) / 2) + 15px);
        }
    }
    @include media-breakpoint-up(sm) {
        --img-width: 120px;
    }
    @include media-breakpoint-up(xl) {
        --img-width: 160px;
    }
    @include media-breakpoint-up(xxl) {
        --img-width: 180px;
    }
}
