:root {
    --panel--padding: #{map-get($panel--padding, xs)};
    // loop through each breakpoint
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            --panel--padding: #{map-get($panel--padding, $breakpoint)};
        }
    }
}

.panel-padding {
    padding: var(--panel--padding); // default padding
}

.panel {
    display: block;
    padding: var(--panel--padding); // default padding
    & > *:last-child {
        margin-bottom: 0;
    }
}

// themed panel

.panel.themed-panel {
    position: relative;
    z-index: 2;
    background: transparent !important;
    font-size: 1.2rem;
    &:before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--theme--background);
        border: 0;
        pointer-events: none;
        z-index: -1;
        mask-image: $mask--solid, $mask--solid, $mask--solid, $mask--solid, $mask--solid;
        mask-position: 0 0, 0 0, 100% 100%, 100% 100%, 5px 5px;
        mask-size: 100% 2px, 2px 100%, 100% 2px, 2px 100%, calc(100% - 10px) calc(100% - 10px);
        mask-repeat: no-repeat;
    }
    &.outline-panel {
        --theme--background: transparent;
        &:before {
            border: 1px solid var(--theme--panel-border-color);
            mask-image: none;
        }
    }
    &.slanted-panel:before {
        transform: skewX($theme-skew-angle);
    }
}

// news panel 

.panel.themed-panel.news-panel {
    font-size: 1.2rem;
    padding: 8px;
    margin-bottom: 20px;
    .news-panel--image {
        overflow: hidden;
        .img-scale-wrapper {
            overflow: visible;
        }
    }
    .news-panel--content {
        position: relative;
        padding: calc(var(--panel--padding) - 8px);
        h3 a {
            color: $brand--pink;
            text-decoration: none !important;
            &:hover, &:focus {
                color: $brand--pink;
                text-decoration: underline !important;
            }
        }
        p {
            margin-bottom: 10px;
        }
        .news-panel--cta {
            margin: 0;
            position: absolute;
            bottom: -20px;
            right: calc(var(--panel--padding) - 8px);
            text-align: right;
            max-width: calc(100% - (2 * (var(--panel--padding) - 8px)));
        }
    }
}
