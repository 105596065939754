#main-footer {
    background-color: $brand--blue;
    color: $brand--white;
    .footer-links--list {
        display: flex;
        flex-direction: column;
        margin: 0;
        li {
            width: 100%;
            margin: 0;
            a {
                display: block;
                margin: 0;
                padding: 0.5rem;
                text-align: center;
                background-color: rgba($brand--pink, 0);
                color: $brand--white;
                transition: background-color 250ms linear, color 250ms linear;
                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                line-height: $headings-line-height;
                font-style: $headings-font-style;
                font-size: 1rem;
                letter-spacing: $headings-letter-spacing;
                text-transform: uppercase;
                text-decoration: none;
                &:hover, &:focus {
                    background-color: rgba($brand--pink, 1);
                    color: $brand--white;
                    text-decoration: none;
                }
            }
        }
        @include media-breakpoint-up(md) {
            flex-direction: row;
            gap: 20px 30px;
            flex-wrap: wrap;
            justify-content: center;
            li {
                width: fit-content;
                a {
                    padding: 0;
                    background-color: transparent;
                    font-size: 1.125rem;
                    &:hover, &:focus {
                        background-color: transparent;
                        color: $brand--pink;
                    }
                }
            }
        }
    }
    .main-footer--primary-links {
        display: block;
        width: 100%;
    }
    .main-footer--secondary-links {
        display: block;
        width: 100%;
    }
    .main-footer--small-print {
        font-size: 1rem;
    }
}
