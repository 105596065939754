#header-wrapper {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    pointer-events: none;
    padding: 0;
    --bg-height: 72px;
    &.has-location-bar {
        --bg-height: 100px;
    }
    header {
        position: relative;
        transition: transform 300ms ease-in-out;
        will-change: transform;
        --header-logo-min-scale: 1;
        &:before {
            display: block;
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: var(--bg-height);
            background: url('../../img/backgrounds/chaos-grid--top-right.svg'), 
                #{linear-gradient(60deg, rgb(132,28,222) 0%, $brand--navy 30%, $brand--navy 70%, rgb(0,125,240) 100%)};
            background-position: 100% 0, 0 0;
            background-size: max(50vw, 500px) auto,100% 100%;
            background-repeat: no-repeat;
            box-shadow: 0 1px 3px rgba(black, .5);
            pointer-events: none;
            opacity: 0;
            transition: opacity 300ms ease-in-out;
            will-change: opacity;
            @include media-breakpoint-up(lg) {
                background: url('../../img/backgrounds/chaos-grid--top-right.svg'), 
                    url('../../img/backgrounds/chaos-grid--bottom-left.svg'), 
                    #{linear-gradient(60deg, rgb(132,28,222) 0%, $brand--navy 30%, $brand--navy 70%, rgb(0,125,240) 100%)};
                background-position: 100% 0, 0 100%, 0 0;
                background-size: max(50vw, 500px) auto, max(50vw, 500px) auto, 100% 100%;
                background-repeat: no-repeat;
            }
        }
        .header-hidden:not(.menu-active) & {
            transform: translate3d(0,-105%,0);
        }
        .page-scrolled:not(.menu-active) &:before {
            opacity: 1;
            pointer-events: all;
            transition-delay: 300ms;
        }
        .minimal-header &, .menu-active & {
            --header-logo-min-scale: .7;
            @media(min-width: 480px) {
                --header-logo-min-scale: .65;
            }
            @include media-breakpoint-up(sm) {
                --header-logo-min-scale: .63;
            }
            @include media-breakpoint-up(md) {
                --header-logo-min-scale: .6;
            }
        }

        #skip-nav {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 2;
            top: 15px;
            left: 30px;
            color: $brand--white;
            text-decoration: underline;
            opacity: 0;
            pointer-events: none;
            transition: opacity 200ms linear;
            &:focus {
                opacity: 1;
                pointer-events: all;
            }
        }

        .main-header--location-bar {
            position: relative;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 35px;
            background-color: rgba(black, .4);
            backdrop-filter: blur(10px);
            .dropdown, .dropdown-center {
                pointer-events: all;
                .dropdown-toggle {
                    text-decoration: none;
                    font-size: 0.875rem;
                    &:after {
                        margin-left: 0.1em;
                        vertical-align: 0.1em;
                        border-top: 0.5em solid $brand--pink;
                        border-right: 0.4em solid transparent;
                        border-left: 0.4em solid transparent;
                    }
                    &:hover, &:focus {
                        text-decoration: underline;
                        &:after {
                            border-top-color: $brand--white;
                        }
                    }
                }
                .dropdown-menu {
                    border-radius: 10px;
                    overflow: hidden;
                }
            }
        }

        .main-header--content {
            display: flex;
            flex-direction: row;
            gap: 10px;
            position: relative;
            z-index: 1;
            margin: 0 auto;
            width: 100%;
            max-width: map-get($container-max-widths, lg);
            padding-top: 15px;
            @include media-breakpoint-up(sm) {
                padding-left: var(--edge-padding);
                padding-right: var(--edge-padding);
            }
            @include media-breakpoint-up(xl) {
                max-width: #{map-get($container-max-widths, xl)};
            }
            @include media-breakpoint-up(xxl) {
                max-width: #{map-get($container-max-widths, xxl)};
            }

            .main-header--content--left {
                width: 25%;
                flex: 0 0 auto;
                text-align: left;
                overflow: hidden;
                @include media-breakpoint-up(sm) {
                    overflow: visible;
                }
            }
            .main-header--content--center {
                width: 50%;
                flex: 0 1 auto;
                text-align: center;
            }
            .main-header--content--right {
                width: 25%;
                flex: 0 0 auto;
                text-align: right;
                overflow: hidden;
                @include media-breakpoint-up(sm) {
                    overflow: visible;
                }
            }

            .main-header--book-button {
                pointer-events: all;
                @include media-breakpoint-up(md) {
                    min-width: 140px;
                }
            }
    
            .main-header--logo {
                display: block;
                margin: -2px auto 0;
                width: 100%;
                max-width: 300px;
                pointer-events: all;
                transform-origin: 50% 0;
                will-change: transform;
                transition: transform 500ms cubic-bezier(0.34, 1.56, 0.64, 1);
                .page-scrolled & {
                    transform: scale(var(--header-logo-min-scale));
                    transition-timing-function: ease-in;
                }
                .menu-active & {
                    transform: scale(var(--header-logo-min-scale));
                    transition-timing-function: ease-in-out;
                }
            }
    
            .main-header--menu-toggle {
                pointer-events: all;
                --bar-width: #{$header--menu-btn-bar--width};
                --bar-height: #{$header--menu-btn-bar--height};
                --bar-spacing: #{$header--menu-btn-bar--spacing};
                transition: filter 300ms linear;
                @include media-breakpoint-up(md) {
                    min-width: 140px;
                }
                .bars {
                    display: inline-block;
                    width: var(--bar-width);
                    height: calc((3 * var(--bar-height)) + (2 * var(--bar-spacing)));
                    margin: -3px 0;
                    span {
                        display: block;
                        position: relative;
                        width: var(--bar-width);
                        height: calc((3 * var(--bar-height)) + (2 * var(--bar-spacing)));
                        transition: transform 200ms ease-in-out;
                        will-change: transform;
                    }
                    i {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: var(--bar-width);
                        height: var(--bar-height);
                        border-radius: $header--menu-btn-bar--border-radius;
                        background-color: var(--theme--btn--color);
                        transition: transform 200ms ease-in-out, width 200ms ease-in-out, opacity 200ms ease-in-out;
                        will-change: transform;
                        &:nth-child(2) {
                            top: calc(var(--bar-height) + var(--bar-spacing));
                        }
                        &:nth-child(3) {
                            top: auto;
                            bottom: 0;
                        }
                    }
                }
                &:hover, &:focus {
                    .bars {
                        i {
                            background-color: var(--theme--btn--color--hover);
                        }
                    }
                }
                &[aria-expanded="true"] {
                    .bars {
                        span {
                            transform: rotate(-90deg);
                        }
                        i {
                            &:nth-child(1) {
                                transform: translate3d(0,calc(var(--bar-height) + var(--bar-spacing)),0) rotateZ(45deg);
                            }
                            &:nth-child(2) {
                                transform: scaleX(0);
                            }
                            &:nth-child(3) {
                                transform: translate3d(0,calc(-1 *(var(--bar-height) + var(--bar-spacing))),0) rotateZ(-45deg);
                            }
                        }
                    }
                }
            }

            // mobile button positioning adjustments

            @media(max-width: 575px) {
                .container-fluid, .container-lg {
                    padding-left: 0;
                    padding-right: 0;
                }
                .main-header--book-button {
                    width: 100%;
                    padding-left: 1rem;
                    padding-right: .75rem;
                    transform: translateX(-5px);
                    @media(max-width: 349px) {
                        font-size: 0.875rem;
                    }
                }
                .main-header--menu-toggle {
                    width: 100%;
                    padding-left: .75rem;
                    padding-right: 1rem;
                    transform: translateX(5px);
                }
            }
        }

        .main-header--location-bar + .main-header--content {
            padding-top: 10px;
        }
    }
}
